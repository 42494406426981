export default [
  {
    path: '/my-wards',
    name: 'MyWards',
    component: () => import('@/views/modules/parent/MyWards.vue'),
    meta: {
      roles: ['parent'],
    },
  },
]
